import React from 'react';
import './Training.scss';
import Badge from '../common/Badge';
import {Training} from "../trainingsplan/Trainingsplan";
import {format, parse} from 'date-fns';
import { de } from 'date-fns/locale'
import sortBy from 'lodash/sortBy'
interface TrainingProps {
    training: Training;
    signedUp?: boolean;
    cancelationReason?: string;
    onAddUserToTrainingClicked: () => void;
    onRemoveUserFromTrainingClicked: () => void;
}

function formatDate(timestamp: string) {
    const date = parse(timestamp, 'yyyy-MM-dd HH:mm', new Date())
    return format(date, 'E, d.M. - H:mm', {locale: de})
}


const TrainingView: React.FC<TrainingProps> = (props) => {
    const trainingFull = props.training.participants.length >= props.training.maxParticipants;
    const sortedParticipants = sortBy(props.training.participants, 'name');
    return (
        <div className="training">
            <div className="training__head">
                <span className="training__date">
                    {formatDate(props.training.timestamp)}
                </span>
                {props.signedUp && <Badge type="ok" label="Angemeldet" />}
                {props.cancelationReason && <Badge type="error" label={props.cancelationReason} />}
            </div>
            <div className="training__participants">
                <span>Teilnehmer:</span>
                <ul className="training__participants-list">
                    {sortedParticipants.map(name => <li key={name}>{name}</li>)}
                </ul>
            </div>
            <div className="training__actions">
                {!props.signedUp && !trainingFull && <button className="training__button training__button--signup" onClick={props.onAddUserToTrainingClicked}>Anmelden</button>}
                {!props.signedUp && trainingFull && <button className="training__button training__button--full" disabled={true}>Training voll</button>}
                {props.signedUp && <button className="training__button training__button--cancel" onClick={props.onRemoveUserFromTrainingClicked}>Abmelden</button>}
            </div>
        </div>
    )
}

export default TrainingView;

import {User} from "./trainingsplan/Trainingsplan";

export function getUser() {
    const data = localStorage.getItem('trainingsplan-user');
    if (!data) {
        return null;
    }
    return JSON.parse(data);
}

export function setUser(user: User) {
    localStorage.setItem('trainingsplan-user', JSON.stringify(user));
}

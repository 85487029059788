import React, {useState} from 'react';
import {loadUser} from "./api";
import {User} from "./trainingsplan/Trainingsplan";
import './PinInput.scss';

interface Props {
    onSubmit: (user: User) => void;
}

function getPinParameter() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('pin');
}

const PinInput: React.FC<Props> = (props) => {
    const pinParameter = getPinParameter();
    const [textValue, setTextValue] = useState(pinParameter ?? '');

    async function onSubmit() {
        if (textValue) {
            if (textValue.length !== 4) {
                alert('PIN muss aus 4 Ziffern bestehen');
                return;
            }
            try {
                const result = await loadUser(textValue);
                if (result && result.hasOwnProperty('name')) {
                    props.onSubmit(result)
                }
            } catch (e) {
                alert('PIN inkorrekt')
            }
        }
    }

    async function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            onSubmit()
        }
    }

    return (
        <div className="pin">
            <span className="pin__caption">Bitte PIN eingeben:</span>
            <input className="pin__input" onKeyPress={onKeyPress} type="text" value={textValue} onChange={(event) => setTextValue(event.target.value)} />
            <button className="pin__button" onClick={() => onSubmit()}>OK</button>
        </div>
    );
}

export default PinInput;

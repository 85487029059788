import {getUser} from "./LocalStorage";

// const baseUrl = "http://localhost:3000/dev"
const baseUrl = "https://b2yebgz15m.execute-api.eu-central-1.amazonaws.com/dev"

function getHeaders() {
    const pin = getUser()?.pin;
    if (!pin) {
        return;
    }
    return {pin};
}

export async function loadTrainings() {
    const pin = getUser()?.pin;
    if (!pin) {
        return;
    }
    return fetch(baseUrl + '/trainings', {headers: getHeaders()}).then(res => res.json());
}

export async function loadUser(pin: string) {
    return fetch(baseUrl + '/user', {headers: {pin}}).then(res => res.json());
}

export async function addUserToTraining(timestamp: string) {
    const body = JSON.stringify({timestamp});
    return fetch(baseUrl + '/trainings/add', {body, method: 'post', headers: getHeaders()}).then(res => res.json());
}

export async function removeUserFromTraining(timestamp: string) {
    const body = JSON.stringify({timestamp});
    return fetch(baseUrl + '/trainings/remove', {body, method: 'post', headers: getHeaders()}).then(res => res.json());
}

import React from 'react';
import './LayoutWrapper.scss';

const LayoutWrapper: React.FC = (props) => {
    return (
        <div className="layout-wrapper">
            <div className="layout-wrapper__head">
                <span className="layout-wrapper__title">Trainingsplan</span>
            </div>
            <div className="layout-wrapper__content">
                {props.children}
            </div>
        </div>
    );
}

export default LayoutWrapper;

import React from 'react';
import './Badge.scss'
import classNames from 'classnames';

interface Props {
    type: "ok" | "error";
    label: string;
}

const Badge: React.FC<Props> = (props) => {
    const className = classNames('badge', {
        'badge--ok': props.type === "ok",
        'badge--error': props.type === "error"
    })
    return (
        <span className={className}>
            {props.label}
        </span>
    );
};

export default Badge;
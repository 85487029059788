import React, {Component} from 'react';
import LayoutWrapper from '../layout/LayoutWrapper';
import TrainingsList from '../list/TrainingsList';
import {loadTrainings, addUserToTraining, removeUserFromTraining} from "../api";

export interface Training {
    date: string;
    time: string;
    timestamp: string;
    participants: string[];
    maxParticipants: number;
}

export interface User {
    pin: string;
    name: string;
}

interface Props {
    user: User;
}

class Trainingsplan extends Component<Props, {trainings: Training[]}> {
    constructor(props: Props) {
        super(props);
        this.state = {
            trainings: []
        };

        this.onAddUserToTraining = this.onAddUserToTraining.bind(this);
        this.onRemoveUserFromTraining = this.onRemoveUserFromTraining.bind(this);
    }

    componentDidMount() {
        loadTrainings().then(trainings => trainings && this.setState({trainings}));
    }

    onAddUserToTraining(timestamp: string) {
        addUserToTraining(timestamp).then(() => {
            loadTrainings().then(trainings => trainings && this.setState({trainings}));
        })
    }

    onRemoveUserFromTraining(timestamp: string) {
        removeUserFromTraining(timestamp).then(() => {
            loadTrainings().then(trainings => trainings && this.setState({trainings}));
        })
    }

    render() {
        const {user} = this.props;
        const {trainings} = this.state;
        return (
            <div>
                <LayoutWrapper>
                    <TrainingsList
                        trainings={trainings} user={user}
                        onAddUserToTraining={this.onAddUserToTraining}
                        onRemoveUserFromTraining={this.onRemoveUserFromTraining}
                    />
                </LayoutWrapper>
            </div>
        );
    }
}

export default Trainingsplan;

import React, {useState} from 'react';
import 'normalize.css';
import './App.scss';
import Trainingsplan, {User} from './trainingsplan/Trainingsplan';
import PinInput from "./PinInput";
import {getUser, setUser} from "./LocalStorage";
import LayoutWrapper from "./layout/LayoutWrapper";

function App() {
  const initialUser = getUser();
  const [user, setUserState] = useState<User>(initialUser)

  function onSubmit(user: User) {
    setUser(user);
    setUserState(user);
  }

  if (!user) {
    return (
        <div>
          <LayoutWrapper>
            <PinInput onSubmit={onSubmit} />
          </LayoutWrapper>
        </div>
    )
  }

  return <Trainingsplan user={user}/>
}

export default App;

import React from 'react';
import "./TrainingsList.scss"
import TrainingView from './Training';
import {Training, User} from "../trainingsplan/Trainingsplan";
import {getISOWeek, parse} from "date-fns";

interface Props {
    trainings: Training[];
    user: User;
    onAddUserToTraining: (timestamp: string) => void;
    onRemoveUserFromTraining: (timestamp: string) => void;
}

const TrainingWeek: React.FC<{week: string} & Props> = (props) => {
    return (
        <div>
            <h2 className="trainings__week">Kalenderwoche {props.week}</h2>
            <ul className="trainings__list">
                {props.trainings.map((training, index, list) => {
                    const signedUp = training.participants.includes(props.user.name);
                    return (
                        <li className="trainings__item" key={training.timestamp}>
                            <TrainingView
                                key={training.timestamp} training={training} signedUp={signedUp}
                                onAddUserToTrainingClicked={() => props.onAddUserToTraining(training.timestamp)}
                                onRemoveUserFromTrainingClicked={() => props.onRemoveUserFromTraining(training.timestamp)}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

const TrainingsList: React.FC<Props> = (props) => {
    const trainingsByWeek = props.trainings.reduce<Record<number, Training[]>>((acc, training) => {
        const date = parse(training.timestamp, 'yyyy-MM-dd HH:mm', new Date())
        const currentWeek: number = getISOWeek(date);
        if (!acc[currentWeek]) {
            acc[currentWeek] = [];
        }
        acc[currentWeek].push(training);
        return acc;
    }, {});

    return (
        <div className="trainings">
            {Object.entries(trainingsByWeek).map(([week, trainings]) => {
                return (
                    <TrainingWeek
                        key={week}
                        onRemoveUserFromTraining={props.onRemoveUserFromTraining}
                        onAddUserToTraining={props.onAddUserToTraining}
                        user={props.user}
                        trainings={trainings}
                        week={week}
                    />
                )
            })}
        </div>

    );
}

export default TrainingsList;
